import IndexVisual from './visual.vue'
import IndexForm from './form.vue'
import IndexPromise from './promise.vue'
import IndexFlow from './flow.vue'
import IndexInfo from './info.vue'
import IndexRecord from './record.vue'
import IndexReview from './review.vue'
import IndexFaq from './faq.vue'
import IndexContact from './contact.vue'
import IndexUsedCar from './used-car.vue'

export default {
  IndexVisual,
  IndexForm,
  IndexPromise,
  IndexFlow,
  IndexInfo,
  IndexRecord,
  IndexReview,
  IndexFaq,
  IndexContact,
  IndexUsedCar,
}
