<template>
  <div :class="$style.root">
    <h2 :class="$style.heading">廃車受付店舗へのお問い合わせ先</h2>
    <div :class="$style.content" style="margin-bottom: 72px">
      <div :class="$style.text">
        <a href="https://showa-metal.jp/" target="_blank">
          <img src="/estimate/img/logo.png" alt="" />
        </a>
        <div :class="$style.officeName">本社＆廃車買取コールセンター</div>
        <address>
          〒343-0012<br />
          埼玉県越谷市増森244-3<br />
          TEL：<a href="tel:048-963-0503">048-963-0503</a><br />
          FAX：048-966-8753<br />
          営業時間：8:00～17:00<br />
          日曜・祝日も営業しております。<br />
        </address>
      </div>
      <div :class="$style.map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d202.00427886703392!2d139.8166777!3d35.8963389!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x78c5f3746284470e!2z5pyJ6ZmQ5Lya56S-IOaYreWSjOODoeOCv-ODqw!5e0!3m2!1sja!2sjp!4v1642673138139!5m2!1sja!2sjp"
          width="600"
          height="300"
          style="border: 0; max-width: 100%"
          allowfullscreen
          loading="lazy"
        ></iframe>
      </div>
    </div>
    <div :class="$style.content">
      <div :class="$style.text">
        <div :class="$style.officeName">岩槻営業所</div>
        <address>
          〒339-0072<br />
          埼玉県さいたま市岩槻区古ヶ場1-7-20<br />
          TEL：<a href="tel:048-793-3477">048-793-3477</a><br />
          FAX：048-793-3478<br />
          営業時間：8:00～17:00<br />
          日曜・祝日も営業しております。<br />
        </address>
      </div>
      <div :class="$style.map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d201.79770987659035!2d139.68839783768172!3d35.9772105594991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c77d182c2f33%3A0x47b56f2cb7503f77!2z5pit5ZKM44Oh44K_44OrIOWyqeanu-WWtualreaJgOODu-Wyqeanu-W3peWgtA!5e0!3m2!1sja!2sjp!4v1643797601571!5m2!1sja!2sjp"
          width="600"
          height="300"
          style="border: 0"
          allowfullscreen
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" module>
.heading {
  width: 100%;
  max-width: 800px;
  margin: map-get($space-xl, y) auto;
  padding: map-get($space-sm, y) map-get($space-xs, x);
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.2em;
  color: $brand;
  border: 6px solid desaturate(lighten($brand, 40), 40);
  background-color: #fff;
}

.content {
  display: grid;
  grid-template: 'text' 'map';
  gap: map-get($space-md, y) map-get($space-md, x);
  @media (min-width: $breakpoint-sm-min) {
    grid-template: 'text map' / 1fr 1fr;
  }
}
.map {
  grid-area: map;
  iframe {
    width: 100%;
  }
}
.text {
  grid-area: text;
  .officeName {
    font-size: 24px;
    line-height: 1;
    font-weight: bold;
    margin: map-get($space-md, y) 0;
    color: $brand;
  }
  address {
    font-size: 16px;
    margin: map-get($space-sm, y) 0;
  }
}
</style>
